import "../../automats/automatnew/new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddBrandMutation } from "../../../api/brandApiSlice";
const AutomatBrandNew = ({ title }) => {
  //requests
  const [automat, setAutomat] = useState({});
  const [addBrand] = useAddBrandMutation()
  const handleChange = (e) => {
    setAutomat((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    
    }
  const notify = (error) => toast(error);
  const navigate = useNavigate();

  const handleClick = async (e) => {
 
    e.preventDefault();
    try {
      if (!automat.brandName) {
        // Handle the case where categoryName is null or empty
        notify("Der Markenname darf nicht leer sein!");
        return;
      }
      await addBrand({brandName:automat.brandName}).unwrap()
        navigate("/automatenbrand")
        } catch (error) {
          notify(error.message)
        }
    }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
   
          <div className="right">
            <form onSubmit={handleClick}>

                   <div className="formInput">
                         <label>Automatenmarke</label>
                    <input name="brandName" onChange={handleChange} type="text" placeholder="Neue brand" />
                   </div>
                   <button className="addUser">Hinzufügen</button>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomatBrandNew
