import React, { useRef, useState } from 'react';
import './login.scss';
import ReCAPTCHA from "react-google-recaptcha"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../redux/authReducer';
import { useLoginMutation } from '../../api/authApiSlice';
import { useVerifyCaptchaTokenMutation } from '../../api/tokenApiSlice';
function Login() {
  const notify = (error) => toast(error);
  const dispatch = useDispatch()
  const [login] = useLoginMutation()
  const [verifyCaptchaToken] = useVerifyCaptchaTokenMutation()
  const [userIdUnsanitized, setUsername] = useState("");
  const [passwordUnsanitized, setPassword] = useState("");
  const captchaRef = useRef();

  const sanitize = (input) => {
    const sanitizedInput = input.replace(/'/g, "''")
                              .replace(/\\/g, "\\\\")
                              .replace(/;/g, "\\;")
                              .replace(/--/g, "\\--")
                              .replace(/#/g, "\\#");
    
    return sanitizedInput;;
  }
const handleLogin = async (e) => {
  e.preventDefault()
  try {
    if (userIdUnsanitized && passwordUnsanitized) {
        let token = captchaRef.current.getValue();
        if(token){
          let valid_token = await verifyToken(token);
            if(valid_token.success){
              const userId = sanitize(userIdUnsanitized);
              const password = sanitize(passwordUnsanitized);
              const { accessToken } = await login({ userId, password }).unwrap()
              dispatch(loginSuccess({user: accessToken }))
              setUsername('')
              setPassword('')
    }
  }
  else{
    notify('Invalid recaptcha!');
  }
}
  else{
    notify('Fill all empty blanks!');
  }
  }
   catch (err) {
      if (!err.status) {
        notify('No Server Response');
      } else if (err.status === 400) {
        notify('Missing Username or Password');
      } else if (err.status === 401) {
        notify('Unauthorized');
      } else {
        notify(err.data?.message);
      }
  }
}

const verifyToken = async (token) => {
  try{
  const response = await verifyCaptchaToken({ token }).unwrap()
  return response.data;
  }catch(error){
  console.log("error ",error);
  }
}

  return (
    <div className="login">
      <img src='/snackki_logo3.png' style={{width:"150px"}} alt='logo' />
      <br/>
      <form onSubmit={handleLogin} className="form">
        <label>
          Benutzername:
          <input type="number" required value={userIdUnsanitized} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Passwort:
          <input type="password" required value={passwordUnsanitized} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} />
         <br/>
        <button type="submit" className="button" >Anmelden</button>
      </form> 
      <ToastContainer />
    </div>
  );
}

export default Login;